import dayjs from 'dayjs';
import { Event, Color, RecurringType } from 'types/calendar';
import { SUPPORT_TIME_ZONE } from 'constants/common';
import { getBrowserTimezoneOffset } from './common';

const getMonthDaysMatrix = (_month: number = dayjs().month()): dayjs.Dayjs[][] => {
    const month = Math.floor(_month);
    const year = dayjs().year();
    const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
    let currentMonthCount = 0 - firstDayOfTheMonth;
    const daysMatrix = new Array(5).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            currentMonthCount++;
            return dayjs(new Date(year, month, currentMonthCount));
        });
    });
    return daysMatrix;
};

const getWeekDaysArray = (date: number = dayjs().day()): dayjs.Dayjs[] => {
    const year = dayjs().year();
    const month = dayjs().month();
    const current = dayjs(new Date(year, month, date));
    const start = current.startOf('week');
    const daysArray = [start];
    for (let i = 1; i < 7; i++) {
        daysArray.push(start.add(i, 'day'));
    }

    return daysArray;
};

const getTimeStr = (date?: dayjs.Dayjs, format?: string): string => {
    if (!date) {
        return ''
    }
    const dateStr = date.format(format || 'YYYY-MM-DD HH:mm:ss');
    return dateStr;
};

const getTimeStrForGoogleCalandar = (date: dayjs.Dayjs, timezone?: string) => {
    let dateStr = date.format('YYYY-MM-DDTHH:mm:ss');
    let offset = getBrowserTimezoneOffset();
    if (timezone) {
        const target = SUPPORT_TIME_ZONE.find((item) => item.dictKey.toLowerCase() === timezone.toLowerCase());
        if (target) {
            offset = target.offset;
        }
    }
    dateStr = `${dateStr}${offset}:00`;
    return dateStr;
};

const getDateKey = (date: dayjs.Dayjs): string => {
    return date.format('YYYY-MM-DD');//fix format
};

const getEventColor = (event: Event, colors: Color[]): string => {
    const target = colors?.find((color) => {
        return color.dictKey === event.colorId;
    });
    return target ? target.content : '#4499df';
};

const isEventTimeOverlap = (event1: Event, event2: Event): boolean => {
    const event1Start = new Date(event1.start.dateTime).getTime();
    const event1End = new Date(event1.end.dateTime).getTime();
    const event2Start = new Date(event2.start.dateTime).getTime();
    const event2End = new Date(event2.end.dateTime).getTime();
    if (event1End < event2Start) {
        return false;
    }
    if (event2End < event1Start) {
        return false;
    }
    return true;
};

const getRecurrence = (time: dayjs.Dayjs, type: RecurringType): string | undefined => {
    if (type === RecurringType.DAILY) {
        return 'RRULE:FREQ=DAILY';
    }
    if (type === RecurringType.NOT_REPEAT) {
        return undefined;
    }
    if (type === RecurringType.WEEKLY) {
        const weekDay = time.format('dd');
        return `RRULE:FREQ=WEEKLY;BYDAY=${weekDay.toLocaleUpperCase()}`;
    }
    if (type === RecurringType.MONTHLY_ON_FIRST_WEEKDAY) {
        const weekDay = time.format('dd');
        return `RRULE:FREQ=MONTHLY;BYDAY=1${weekDay.toLocaleUpperCase()}`;
    }
    if (type === RecurringType.MONTHLY_ON_DATE) {
        return 'RRULE:FREQ=MONTHLY';
    }
    if (type === RecurringType.WEEKDAY) {
        const weekDay = time.format('dd');
        return `RRULE:FREQ=WEEKLY;BYDAY=${weekDay.toLocaleUpperCase()}`;
    }
    return undefined;
};

const getRecurrenceTypeFromStr = (recurrence?: string): RecurringType => {
    if (!recurrence) {
        return RecurringType.NOT_REPEAT;
    }
    if (recurrence.includes('RRULE:FREQ=DAILY')) {
        return RecurringType.DAILY;
    }
    if (recurrence.includes('RRULE:FREQ=MONTHLY;BYDAY=1')) {
        return RecurringType.MONTHLY_ON_FIRST_WEEKDAY;
    }
    if (recurrence.includes('RRULE:FREQ=MONTHLY')) {
        return RecurringType.MONTHLY_ON_DATE;
    }
    if (recurrence.includes('RRULE:FREQ=WEEKLY;BYDAY=')) {
        return RecurringType.WEEKDAY;
    }
    if (recurrence.includes('RRULE:FREQ=WEEKLY')) {
        return RecurringType.WEEKLY;
    }

    return RecurringType.NOT_REPEAT;
};

export {
    getMonthDaysMatrix,
    getWeekDaysArray,
    getTimeStr,
    getTimeStrForGoogleCalandar,
    getDateKey,
    getEventColor,
    isEventTimeOverlap,
    getRecurrence,
    getRecurrenceTypeFromStr,
};
