import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Spin } from 'antd';
import { TPageManagement, type TMedicationSetting, type TObjectAction } from 'types/helloKlarity';
import EditMedication from '../EditMedication';
import { getMedicationList } from 'api/operation/pageManagement';

import s from './s.module.less';

type IProps = {
    pageId?: string;
    isLoading: boolean;
    onSuccess: (obj: TObjectAction) => void;
    onPreview: (list: TMedicationSetting[]) => void;
};

const MedicationManagement: React.FC<IProps> = ({
    pageId,
    isLoading,
    onPreview,
    onSuccess,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [item, setItem] = useState<TMedicationSetting>();
    const [medications, setMedications] = useState<TMedicationSetting[]>([]);
    const [currentIndex, setIndex] = useState<number>(1);

    const handleEdit = (selectItem: TMedicationSetting, index: number) => {
        setItem(selectItem);
        setIndex(index);
        setIsOpen(true);
    };

    const handleSuccess = (obj: TObjectAction) => {
        setIsOpen(false);
        setItem(undefined);
        onSuccess(obj);
        getList();
    };

    const getList = async () => {
        const { data } = await getMedicationList();
        setMedications(data?.data || []);
    };

    useEffect(() => {
        onPreview(medications);
    }, [medications]);

    // useEffect(() => {
    //     setService(goLiveObj.services);
    // }, [goLiveObj]);

    useEffect(() => {
        getList();
    }, []);

    return (
        <div className={s.wrap}>
            <Spin spinning={isLoading}>
                <div className={s.wrapper}>
                    {medications?.map((item: TMedicationSetting, index) => (
                        <div className={s.panel} key={index}>
                            <Descriptions
                                title={`Medication ${index + 1}`}
                                layout="vertical"
                                extra={
                                    <Button onClick={() => handleEdit(item, index)}>
                                        Edit
                                    </Button>
                                }
                                column={1}
                                size="small"
                                contentStyle={{
                                    marginBottom: '16px',
                                }}
                            >
                                <Descriptions.Item label="Medication page">
                                    {item.medicationPageUrl}
                                </Descriptions.Item>
                                <Descriptions.Item label="Subtext">
                                    {item.subtext}
                                </Descriptions.Item>
                                <Descriptions.Item label="Tag">
                                    {item.tag}
                                </Descriptions.Item>
                                <Descriptions.Item label="Image">
                                    {item.imageUrl && (
                                        <img
                                            src={item.imageUrl}
                                            alt={item.subtext}
                                            className={s.serviceLogo}
                                        />
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    ))}
                </div>
            </Spin>
            {isOpen && (
                <EditMedication
                    currentIndex={currentIndex}
                    item={item}
                    list={medications}
                    onCancel={() => setIsOpen(false)}
                    onSuccess={handleSuccess}
                />
            )}
        </div>
    );
};

export default MedicationManagement;
