import React, { useState, useMemo } from 'react';
import { Modal, Form, Input, Button, Tooltip } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { TMedicationSetting, type TObjectAction, type TServiceSetting } from 'types/helloKlarity';
import KlarityServiceSelect from 'components/form/KlarityServiceSelect';
import FileUpload from 'components/form/FileUpload';
import { klarityMedicationUpdate, klarityServiceUpdate } from 'api/operation/pageManagement';
import { REQUIRED_FIELD } from 'constants/common';

type Props = {
    currentIndex: number;
    item?: TMedicationSetting;
    list: TMedicationSetting[];
    onCancel?: () => void;
    onSuccess?: (obj: TObjectAction) => void;
};

const EditMedication = ({
    currentIndex,
    item,
    list,
    onCancel,
    onSuccess,
}: Props) => {
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<TMedicationSetting & { active: boolean }>();
    const isEdit = true;

    const onSave = async () => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const id = item?.id;

            const params: TMedicationSetting = {
                tag: formValues.tag,
                subtext: formValues.subtext,
                imageUrl: formValues.imageUrl,
                pageId: item?.pageId ?? 1,
                id: item?.id,
                seq: item?.seq ?? 1,
                medicationPageUrl: item?.medicationPageUrl!,
            };
            if (isEdit) {
                params.id = id;
            }
            const { data } = await klarityMedicationUpdate(params);
            if (data.code === 200) {
                onSuccess?.({
                    type: 'MEDICATION_EDIT',
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                medicationPageUrl: item.medicationPageUrl,
                subtext: item.subtext,
                imageUrl: item.imageUrl,
                tag: item.tag,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <Modal
            title={`Edit Medication ${currentIndex + 1}`}
            closable
            className={commonS.modalFixHeightWrap}
            width="500px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>
                        Save changes
                    </Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            className={s.rowItem}
                            label="Medication page"
                            name="medicationPageUrl"
                            rules={[
                                { required: true, message: REQUIRED_FIELD },
                            ]}
                        >
                            <Input
                                placeholder='Ex. /medication/semaglutide-online-prescription/'
                                maxLength={100} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Subtext"
                            name="subtext"
                            rules={[
                                { required: true, message: REQUIRED_FIELD },
                            ]}
                        >
                            <Input maxLength={100} style={{ width: '100%' }} showCount />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Tag"
                            name="tag"
                            rules={[
                                { required: true, message: REQUIRED_FIELD },
                            ]}
                        >
                            <Input maxLength={30} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label={
                                <div className={s.formLabel}>
                                    Image
                                    <Tooltip
                                        overlayClassName={s.tipCard}
                                        overlayInnerStyle={{ padding: '12px' }}
                                        title={
                                            <div className={s.tipText}>
                                                Image should have a transparent background and be
                                                sized 278x128 pixels.
                                            </div>
                                        }
                                    >
                                        <div className={s.questionMarkIcon} />
                                    </Tooltip>
                                </div>
                            }
                            name={['imageUrl']}
                            rules={[{ required: true, message: 'Image is required' }]}
                        >
                            <FileUpload />
                        </Form.Item>
                    </>
                </Form>
            </div>
        </Modal>
    );
};

export default EditMedication;
