// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__conditionLogo--AiIAZ {
  width: 98px;
  height: 98px;
  border-radius: 24px;
  background-color: #fff;
}
.s-module__wrapper--oA3TN {
  min-height: 20vh;
  margin-top: 20px;
}
.s-module__wrapper--oA3TN .ant-descriptions-row > th {
  padding-bottom: 4px;
}
.s-module__wrapper--oA3TN .ant-descriptions-row > td {
  padding-bottom: 0;
}
.s-module__panel--fQoLD {
  border: 1px solid #DAD8D8;
  padding: 24px 24px 32px 24px;
  border-radius: 10px;
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/components/MedicationManagement/s.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;AACF;AAEA;EACE,gBAAA;EACA,gBAAA;AAAF;AAFA;EAKM,mBAAA;AAAN;AALA;EAQM,iBAAA;AAAN;AAKA;EACE,yBAAA;EACA,4BAAA;EACA,mBAAA;EACA,mBAAA;AAHF","sourcesContent":[".conditionLogo {\n  width: 98px;\n  height: 98px;\n  border-radius: 24px;\n  background-color: #fff;\n}\n\n.wrapper {\n  min-height: 20vh;\n  margin-top: 20px;\n  :global {\n    .ant-descriptions-row >th {\n      padding-bottom: 4px;\n    }\n    .ant-descriptions-row >td {\n      padding-bottom: 0;\n    }\n  }\n}\n\n.panel {\n  border: 1px solid #DAD8D8;\n  padding: 24px 24px 32px 24px;\n  border-radius: 10px;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conditionLogo": `s-module__conditionLogo--AiIAZ`,
	"wrapper": `s-module__wrapper--oA3TN`,
	"panel": `s-module__panel--fQoLD`
};
export default ___CSS_LOADER_EXPORT___;
